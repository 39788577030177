import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  token: any;
  constructor(private router: Router) {}
  canActivate(): boolean {
    // console.log(localStorage.getItem('token'));
    this.token = localStorage.getItem('token');
    if (!this.token) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
