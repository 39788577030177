import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token = localStorage.getItem('token');
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        // 'Content-Type': 'application/json; charset=utf-8',
        // Accept: 'application/json',
        Authorization: `token ${this.token ? this.token : localStorage.getItem('token')}`,
      },
    });
    return next.handle(req);
  }
}
